<template>
  <div class="container">
    <h4 class="titles my-4 mx-3">{{ title }}</h4>
    <div class="row mx-3 mb-3">
      <div class="col">
        <div class="card pointer menuCard" role="button">
          <div class="card-img-top text-secondary" v-if="appointmentInfo">
            <font-awesome-icon
              v-if="!appointmentInfo.cash_register_confirmation"
              size="3x"
              class="my-5 card-img-top text-danger"
              icon="exclamation-triangle" />
            <font-awesome-icon
              v-if="appointmentInfo.cash_register_confirmation"
              size="3x"
              class="my-5 card-img-top text-success"
              icon="circle-check" />
          </div>
          <hr />
          <div class="card-body text-justify" v-if="appointmentInfo">
            <h5 class="mb-2" v-if="!appointmentInfo.cash_register_confirmation">
              <b>¿Estás seguro que deseas autorizar este evento?</b>
            </h5>
            <h5 v-if="appointmentInfo.cash_register_confirmation">
              <b>Autorizada por:</b> {{ appointmentInfo.cash_register_name }}
            </h5>
            <div class="text-justify">
              <p class="mb-1">
                <b>Paciente:</b> {{ appointmentInfo.patient_name }}.
              </p>
              <p class="mb-1">
                <b>Médico asignado:</b> {{ appointmentInfo.medic_name }}.
              </p>
              <p class="mb-1"><b>Fecha:</b> {{ appointmentInfo.date }}.</p>
              <p class="mb-1"><b>Hora:</b> {{ appointmentInfo.time }}.</p>
              <p class="mb-1"><b>Servicio:</b> {{ servicesStringMaker }}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2 pe-3">
      <b-btn
        title="Nuevo Cliente"
        variant="primary"
        @click="authAppointment"
        v-if="appointmentInfo && !appointmentInfo.cash_register_confirmation"
        class="float-end">
        Autorizar
      </b-btn>
      <b-btn
        title="Nuev Ruta"
        variant="secondary"
        @click="hideModal"
        v-if="appointmentInfo"
        class="float-end me-3">
        {{ appointmentInfo.cash_register_confirmation ? "Cerrar" : "Cancelar" }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AuthAppointment",
  data() {
    return {
      appointmentInfo: null,
    };
  },
  computed: {
    ...mapGetters(["ceAppointmentEditID"]),
    title() {
      if (!this.appointmentInfo) {
        return "";
      }
      return this.appointmentInfo.cash_register_confirmation
        ? "Evento autorizado"
        : "Autorizar evento";
    },
    servicesStringMaker() {
      let servicesString = "";
      this.appointmentInfo.services.forEach((service) => {
        servicesString += `${service.name}, `;
      });
      return servicesString.slice(0, -2);
    },
  },
  methods: {
    ...mapMutations(["setCEAppointmentEditID"]),
    authAppointment() {
      const appointmentID = this.ceAppointmentEditID;
      this.$api.appointments.authConfirmation(appointmentID).then(() => {
        this.$emit("reloadPatientList");
        this.hideModal();
      });
    },
    getAppointmentInfo() {
      const appointmentID = this.ceAppointmentEditID;
      if (!appointmentID) {
        this.hideModal();
        return;
      }
      this.$api.appointments.getInfo(appointmentID).then((response) => {
        this.appointmentInfo = response.data;
      });
    },
    hideModal() {
      this.setCEAppointmentEditID();
      this.$emit("resetComponent");
      this.$store.getters.getModal("generalFiliationModal").hide();
    },
  },
};
</script>

<style></style>
